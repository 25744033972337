.footer-container {
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center; }
  .footer-container .footer-top {
    display: flex;
    flex: 1 1 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 6rem 0; }
    .footer-container .footer-top ul {
      display: flex;
      flex: 1 1 100%;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin: unset;
      padding: unset;
      list-style-type: none;
      display: flex;
      flex: 1; }
      .footer-container .footer-top ul li {
        padding: 0 2rem; }
  .footer-container .footer-bottom {
    display: flex;
    flex: 1 1 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
    background-color: black;
    color: white; }
    .footer-container .footer-bottom .disclaimer-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      padding: 0 2rem; }
    .footer-container .footer-bottom .digigrow-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      padding: 0 2rem; }

@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
  .footer-container .footer-top {
    padding: 2rem 0; }
    .footer-container .footer-top ul {
      flex-direction: column;
      align-items: center; }
      .footer-container .footer-top ul li {
        padding: 1rem 0; }
  .footer-container .footer-bottom {
    justify-content: center; }
    .footer-container .footer-bottom .disclaimer-container {
      padding: 0 2rem; }
    .footer-container .footer-bottom .digigrow-container {
      padding: 0 2rem; }
      .footer-container .footer-bottom .digigrow-container img {
        margin-left: 1rem; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .footer-container .footer-top {
    padding: 4rem 0; }
    .footer-container .footer-top ul {
      flex-direction: row; }
      .footer-container .footer-top ul li {
        padding: 0 1rem; }
  .footer-container .footer-bottom {
    justify-content: center; }
    .footer-container .footer-bottom .disclaimer-container {
      padding: 0 2rem; }
    .footer-container .footer-bottom .digigrow-container {
      padding: 0 2rem; }
      .footer-container .footer-bottom .digigrow-container img {
        margin-left: 1rem; } }

.twoCol-container {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0 9.6875rem; }
  .twoCol-container .bg-image {
    position: absolute; }
  .twoCol-container .container-row {
    flex: 1 1 100%; }
    .twoCol-container .container-row .container-column {
      display: flex;
      flex: 1 1 calc(50% - 2rem);
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .twoCol-container .container-row .container-column.left {
        padding-right: 1rem; }
      .twoCol-container .container-row .container-column.right {
        padding-left: 1rem; }
      .twoCol-container .container-row .container-column img {
        max-width: 100%; }

@media only screen and (max-width: 1024px) {
  .twoCol-container {
    margin: 0 1.125rem; }
    .twoCol-container .container-row .container-column {
      flex: 1 1 100%; } }

.multiCol-container {
  flex: 1 1 auto; }
  .multiCol-container .bg-image {
    position: absolute; }
  .multiCol-container .multiCol-header {
    flex: 1 1 100%;
    padding: 1rem 0; }
  .multiCol-container .container-row {
    flex: 1 1 100%;
    align-items: stretch;
    padding: 1rem 0; }
    .multiCol-container .container-row .container-column {
      flex: 0 1 auto;
      margin: 1rem;
      align-content: flex-start; }
  .multiCol-container .container-bottom {
    flex: 1 1 100%;
    padding: 1rem 0; }

@media only screen and (max-width: 1024px) {
  .multiCol-container {
    padding: 2rem 1rem; }
    .multiCol-container .container-row {
      flex: 1 1 100%; }
      .multiCol-container .container-row .container-column {
        flex: 1 1 100%;
        margin: 1rem 0; } }

.card {
  align-content: space-between;
  margin: 1rem;
  flex-direction: column;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .card .image-container {
    flex: 1 1 100%; }
    .card .image-container img {
      max-width: 100%; }
  .card .body-container {
    flex: 1 1 100%; }

@media only screen and (max-width: 1024px) {
  .card {
    flex: 1 1 100%; } }
